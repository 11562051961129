/* eslint-disable react/no-danger */
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import { Button, Divider, ECImage, Section } from 'components/ui';
import ContentBlock from 'components/ui/ContentBlock';
import { ImageSetKeys, getImageSets, useImages } from 'context/ImagesProvider';
import Layout from 'layouts/Layout';
import serverSideSeo from 'services/seo/serverSideSeo';
import serverSideTranslations from 'services/translations/serverSideTranslations';
import { useTranslations } from 'services/translations/TranslationsProvider';
import createNextPage from 'utils/pages/createNextPage';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default createNextPage<Props>(
  () => {
    const { t } = useTranslations('page.landing.bcorp');
    const { img } = useImages(ImageSetKeys.PageLandingBCorp);
    const { locale } = useRouter();

    const content = [
      {
        title: 'BCorpOurPeople',
        description: 'BCorpOurPeopleDescription',
        perks: ['BCorpOurPeoplePerk1', 'BCorpOurPeoplePerk2', 'BCorpOurPeoplePerk3'],
        image: 'BCorpOurPeopleImg',
        href: 'https://careers.edgardcooper.com/',
        cta: 'BCorpOurPeopleCta',
      },
      {
        title: 'BCorpOurCommunity',
        description: 'BCorpOurCommunityDescription',
        perks: ['BCorpOurCommunityPerk1', 'BCorpOurCommunityPerk2', 'BCorpOurCommunityPerk3'],
        image: 'BCorpOurCommunityImg',
        href: `/${locale}/zero-pawprint-plan/`,
        cta: 'BCorpOurCommunityCta',
      },
      {
        title: 'BCorpEnvironment',
        description: 'BCorpEnvironmentDescription',
        perks: ['BCorpEnvironmentPerk1', 'BCorpEnvironmentPerk2', 'BCorpEnvironmentPerk3'],
        image: 'BCorpEnvironmentImg',
        href: `/${locale}/zero-pawprint-plan/`,
        cta: 'BCorpOurCommunityCta',
      },
      {
        title: 'BCorpOurCustomers',
        description: 'BCorpOurCustomersDescription',
        perks: ['BCorpOurCustomersPerk1', 'BCorpOurCustomersPerk2', 'BCorpOurCustomersPerk3'],
        image: 'BCorpOurCustomersImg',
        href: 'https://faq.edgardcooper.com/',
        cta: 'lookingForHelp',
      },
      {
        title: 'BCorpOurMission',
        description: 'BCorpOurMissionDescription',
        perks: [],
        image: 'BCorpOurMissionImg',
        href: 'https://cdn.edgardcooper.com/pdf/edgard_cooper_impact_report_2023.pdf',
        cta: 'readImpactReport',
      },
    ];

    return (
      <div className="overflow-hidden">
        <div className="bg-cyan-400 overflow-hidden relative">
          <div className="container">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-6 flex items-center ">
                <div className="py-16 lg:py-20 lg:pr-12 flex-grow">
                  <div className="max-w-copy maxLg:mx-auto text-center">
                    <h1 className="font-rodetta text-white text-3xl lg:text-4xl">
                      {t('BCorpHeroTitle')}
                    </h1>
                    <h2 className="font-rodetta text-blue-400 text-3xl lg:text-4xl">
                      {t('BCorpHeroTitle2')}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6 relative">
                <ECImage
                  img={img('BCorpHero')}
                  srcOptions={{
                    w: 1500,
                    q: 90,
                  }}
                  className="w-full h-full object-cover object-center"
                  pictureClassName="block aspect-[4/3] w-auto -mx-4 sm:-mx-8 lg:mx-0 lg:min-h-full lg:h-full lg:w-[50vw] 2xl:max-h-[60vh]"
                />
              </div>
            </div>
          </div>
        </div>

        <Section
          color="white"
          className="container space-y-1 text-blue-400 font-rooney font-bold flex items-center text-center px-4"
        >
          <p dangerouslySetInnerHTML={{ __html: t('BCorpStatement1') }} />
        </Section>

        <section className=" bg-brown-100">
          {content.map((item, index) => (
            <Section key={item.description} noBottomSpacing className="last:pb-12">
              <ContentBlock reverse={index % 2 !== 0} className="container items-center gap-4">
                <div className="">
                  <div className="max-w-copy md:text-center mb-8">
                    <div>
                      <div className="font-rooney font-medium text-lg text-purple-700 flex flex-col gap-6">
                        <h3 className="text-3xl lg:text-4xl text-blue-400 font-rodetta text-left">
                          {t(item.title)}
                        </h3>
                        <p className="text-blue-400 text-xl text-left">{t(item.description)}</p>
                      </div>
                    </div>
                  </div>
                  <ul className="list-disc list-inside space-y-2 mb-8 font-rooney">
                    {item.perks.map((perk) => (
                      <li key={perk}>{t(perk)}</li>
                    ))}
                  </ul>
                  <Button external href={item.href} color="blue">
                    {t(item.cta)}
                  </Button>
                </div>
                <div className="">
                  <ECImage
                    img={img(item.image)}
                    srcOptions={{
                      w: 1200,
                    }}
                    className="rounded-xl justify-self-end lg:w-[500px] object-cover object-center"
                  />
                </div>
              </ContentBlock>
            </Section>
          ))}
        </section>

        <Section
          color="white"
          className="container--md mx-auto space-y-1 text-blue-400 font-rooney font-bold flex items-center text-center px-5"
        >
          <p className="mb-4">{t('BCorpCompromise')}</p>

          <Button
            external
            href="https://www.bcorporation.net/find-a-b-corp/company/edgard-cooper/"
            className="!bg-[#65c2c4]"
          >
            {t('learnMore')}
          </Button>
        </Section>

        <Section color="cyan">
          <div className="max-w-[900px] mx-4 lg:mx-auto bg-white rounded-2xl p-9 lg:py-14 lg:px-24  font-rooney text-blue-400 text-xl font-bold">
            <h3 className="font-rodetta text-3xl lg:text-4xl font-normal text-center">
              {t('BCorpRoadmapTitle')}
            </h3>
            <span className="block text-[#65c2c4] mt-4 text-center text-2xl font-bold">
              {t('BCorpRoadmapMid2019')}
            </span>
            <p className="my-4 text-lg lg:text-xl">{t('BCorpRoadmapMid2019Description')}</p>
            <ul className="mb-2 list-inside">
              <li className="list-disc">{t('BCorpRoadmapMid2019Checkpoint1')}</li>
              <li className="list-disc">{t('BCorpRoadmapMid2019Checkpoint2')}</li>
            </ul>

            <Divider />

            <span className="block text-[#65c2c4] my-4 text-2xl font-bold text-center">
              {t('BCorpRoadmapMarch2021')}
            </span>

            <ul className="list-inside">
              <li className="list-disc">{t('BCorpRoadmapMarch2021Checkpoint')}</li>
            </ul>
            <Divider className="my-10" />
            <span className="block text-[#65c2c4] my-4 text-2xl font-bold text-center">
              {t('BCorpRoadmapMarch2022')}
            </span>

            <ul className="list-inside">
              <li className="list-disc">{t('BCorpRoadmapMarch2022Checkpoint')}</li>
            </ul>
          </div>
        </Section>
      </div>
    );
  },
  {
    Layout,
  },
);

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { locale } = ctx;
  if (locale === 'default') return { props: {} };

  const [resources, images, seo] = await Promise.all([
    serverSideTranslations(
      locale,
      ['page.landing.bcorp', 'content.testimonials', 'content.newsletter', 'form'],
      ctx,
    ),
    getImageSets(
      {
        locale,
        keys: [
          ImageSetKeys.PageLandingBCorp,
          ImageSetKeys.ContentProductQuizActivation,
          ImageSetKeys.PageHome2024,
        ],
      },
      ctx,
    ),
    serverSideSeo(locale, 'page.landing.bcorp', ctx),
  ]);

  return !resources
    ? { notFound: true }
    : {
        props: {
          resources,
          seo,
          images,
        },
        revalidate: parseInt(process.env.NEXT_REVALIDATE_SECONDS, 10),
      };
};
